import React from 'react';

import { LoaderFunctionArgs, Outlet, replace, useLoaderData, useNavigate } from 'react-router-dom';
import { ErrorScreen, PaymentProcessingScreen, TroubleshootScreen } from 'screens';
import { PaymentRequestSessionState, PaymentRequestSessionStatus } from 'types';
import { isNotNullOrUndefined, isNullOrUndefined } from 'utils/checks';
import { fetchSessionState } from 'utils/client';
import { SessionStateFetchInProgressError, TerminalOfflineError } from 'utils/errors';

export const paymentRouteLoader = async ({ request, params }: LoaderFunctionArgs) => {
  const sessionId = params.paymentSessionId;

  if (isNullOrUndefined(sessionId)) {
    throw new Error('Missing sessionId.');
  }

  const url = new URL(request.url);
  const token = url.searchParams.get('token');

  if (isNotNullOrUndefined(token)) {
    window.sessionStorage.setItem(`${sessionId}-token`, token);
    return replace(`/payments/${sessionId}`);
  }

  const sessionToken = window.sessionStorage.getItem(`${sessionId}-token`);

  if (isNullOrUndefined(sessionToken)) {
    throw new Error('Missing sessionToken.');
  }

  return {
    sessionId,
    sessionToken,
  };
};

export const PaymentRoute: React.FC = () => {
  const navigate = useNavigate();
  const initialSessionState = useLoaderData() as PaymentRequestSessionState;
  const [screen, setScreen] = React.useState<
    'outlet' | 'terminal-offline' | 'wait' | 'fetch-session-state-error'
  >('wait');

  const [sessionState, setSessionState] =
    React.useState<PaymentRequestSessionState>(initialSessionState);
  const [sessionFetchLocked, setSessionFetchLocked] = React.useState<boolean>(false);

  const refreshSessionState = async (): Promise<PaymentRequestSessionState> => {
    const state = await fetchSessionState(sessionState.sessionId, sessionState.sessionToken);
    setSessionState(state);
    return state;
  };
  const [refreshSessionStateError, setRefreshSessionStateError] = React.useState<unknown>();

  const refreshSessionStateCallback = React.useCallback(refreshSessionState, [sessionState]);

  const refreshSessionStateWithLock = async (): Promise<PaymentRequestSessionState> => {
    try {
      if (!sessionFetchLocked) {
        setSessionFetchLocked(true);
        return await refreshSessionState();
      } else {
        throw new SessionStateFetchInProgressError();
      }
    } finally {
      setSessionFetchLocked(false);
    }
  };

  React.useEffect(() => {
    if (isNotNullOrUndefined(sessionState.session)) {
      setScreen('outlet');
      switch (sessionState.session.status) {
        case PaymentRequestSessionStatus.PENDING_CONFIRMATION:
          navigate(`/payments/${sessionState.sessionId}/confirm`);
          break;

        case PaymentRequestSessionStatus.COMPLETED:
          navigate(`/payments/${sessionState.sessionId}/result`);
          break;

        default:
          navigate(`/payments/${sessionState.sessionId}/processing`);
          break;
      }
    } else if (screen == 'wait') {
      refreshSessionStateCallback().catch((error) => {
        setRefreshSessionStateError(error);
        if (error instanceof TerminalOfflineError) {
          setScreen('terminal-offline');
        } else {
          setScreen('fetch-session-state-error');
        }
      });
    }
  }, [screen, sessionState, navigate, refreshSessionStateCallback]);

  switch (screen) {
    case 'fetch-session-state-error':
      return (
        <ErrorScreen
          error={refreshSessionStateError}
          problem={<>{'Unable to retrieve payment information.'}</>}
          allowRetry={true}
          onRetry={async () => {
            setScreen('wait');
          }}
        />
      );

    case 'wait':
      return <PaymentProcessingScreen status={'Please wait'} />;

    case 'terminal-offline':
      return (
        <TroubleshootScreen
          variant={'terminal-offline'}
          onConfirm={() => {
            setScreen('wait');
          }}
        />
      );

    case 'outlet':
      return (
        <Outlet context={{ sessionState, refreshSessionState: refreshSessionStateWithLock }} />
      );

    default:
      throw new Error(`Unexpected screen '${screen}'.`);
  }
};
